<template>
  <div>
    <div class="ly-paging">
      <div class="ly-center">
        <div class="up">
          <button :disabled="pagination.current=='1'" :class="[pagination.current=='1'?'current':'','btn']"
                  type="button" @click="firstPage">首页
          </button>
          <div class="btn-box">
            <button class="btn" @click="prePage" :disabled="pagination.current=='1'" type="button">上一页</button>
            <button class="btn" @click="nextPage" :disabled="pagination.current>=pagination.pages" type="button">下一页
            </button>
          </div>
          <button class="btn choose" type="button" @click="pagePOP=true">选页</button>
        </div>
        <div class="down">
          <div class="lf">第<span>{{ pagination.current }}</span>/{{ pagination.pages }}页</div>
          <div class="rt">共<span>{{ pagination.total }}</span>个变量
            <div class="select" @click="sizePOP=true">每页显示<span>{{ pagination.size }}</span>个变量</div>
          </div>
        </div>
      </div>
    </div>
    <!--选分页数弹框-->
    <mt-popup v-model="sizePOP" class="ly-pop-wrap size-pop-wrap" popup-transition="popup-fade" style="top: 30%">
      <div class="pop-title">选择每页显示变量数</div>
      <div class="pop-body">
        <div class="size-box">
          <div class="size-item" @click="setSize(10)">10</div>
          <div class="size-item" @click="setSize(20)">20</div>
          <div class="size-item" @click="setSize(30)">30</div>
          <div class="size-item" @click="setSize(50)">50</div>
        </div>
      </div>
    </mt-popup>

    <!--  选页弹框  -->
    <mt-popup v-model="pagePOP" class="ly-pop-wrap page-pop-wrap" position="bottom" popup-transition="popup-fade">
      <div class="pop-title">滑动选择需要跳转的页码</div>
      <div class="pop-body">
        <div class="page-box">
          <div class="page-item" @click="setPage(i)" v-for="i in pagination.pages" :key="i">第{{ i }}页</div>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>

export default {
  name: "Lypaging",
  props: {
    // 分页数据
    pagination: {
      type: Object,
      default: () => ({
        total: 0,
        size: 10,
        current: 1,
        pages: 1
      })
    },
  },
  components: {},
  data() {
    return {
      sizePOP: false,// size 弹框
      pagePOP: false,// 选页码 弹框
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },

  methods: {
    // 设置分页数
    setSize(value) {
      this.pagination.size = value
      this.$emit('sizeChange', value)
      this.sizePOP = false
    },
    // 设置页数
    setPage(value) {
      this.pagination.current = value
      this.$emit('pageChange', value)
      this.pagePOP = false
    },
    //   上一页
    prePage() {
      if (this.pagination.current > 1) {
        this.pagination.current--
        this.$emit("pageChange", this.pagination.current)
      }
    },
    //   下一页
    nextPage() {
      if (this.pagination.current < this.pagination.pages) {
        this.pagination.current += 1
        this.$emit("pageChange", this.pagination.current)
      }
    },
    //   第一页
    firstPage() {
      this.$emit("pageChange", 1)
    },
  }
}
</script>

<style scoped lang="less">
.else-footer {
  .ly-paging {
    width: 7.5rem;
    height: 2.72rem;
    position: fixed;
    background-color: #F2F2F2FF;
    bottom: 1.13rem;
    box-shadow: 0px 4px 4px 0px rgba(8, 8, 8, 0.28);
  }
}

.ly-paging {
  width: 7.5rem;
  //left: 50%;
  //margin-left: -3.75rem;
  height: 2.72rem;
  position: fixed;
  bottom: 0;
  background-color: #F2F2F2FF;

  .btn {
    border: none;
    outline: none;
    width: 1.44rem;
    height: 0.88rem;
    background: #0088FF;
    box-shadow: 0px 4px 4px 0px rgba(0, 136, 255, 0.28);
    border-radius: 10px;
    color: #FFFFFF;
  }

  .btn:disabled {
    color: #FFFFFF;
    background: #CCCCCC;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.28);
  }

  .current {
    background: #FFFFFF;
    border: 0.03rem solid #CCCCCC;
    color: #CCCCCC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  }

  .choose {
    background: #FFFFFF;
    border: 0.03rem solid #0088FFFF;
    color: #0088FF;
    box-shadow: 0 0.04rem 0.04rem 0 rgba(0, 0, 0, 0.28);
  }

  .up {
    margin-top: 0.3rem;
    display: flex;

    .btn-box {
      flex: 1;
      text-align: center;

      .btn:last-child {
        margin-left: 0.2rem;
      }
    }
  }

  .down {
    padding-top: 0.3rem;
    display: flex;
    line-height: 0.88rem;
    color: #999999FF;
    font-size: 0.26rem;

    span {
      color: #333333FF;
    }

    .lf {
      width: 1.5rem;
    }

    .rt {
      flex: 1;
      text-align: right;

      .select {
        margin-left: 0.2rem;
        text-align: center;
        width: 3rem;
        height: 0.88rem;
        background: #FFFFFF;
        border: 1px solid #333333;
        box-shadow:inset 0px 0.03rem 0.03rem 0px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
        display: inline-block;
      }
    }
  }
}

.size-pop-wrap {
  .size-box {
    display: flex;
    padding: 0.3rem 0.2rem 0.4rem;
    text-align: center;

    .size-item {
      margin-left: 0.3rem;
      width: 0.88rem;
      height: 0.88rem;
      text-align: center;
      line-height: 0.88rem;
      color: #0088FFFF;
      background: #FFFFFF;
      border: 1px solid #0088FFFF;
      border-radius: 10px;
    }
  }
}

.page-pop-wrap {
  width: 100%;
  border-radius: 0;
  height: 5.5rem;

  .page-box {
    width: 100%;
    height: 4rem;
    overflow-y: scroll;

    .page-item {
      height: 0.88rem;
      border-bottom: 1px solid #E2E2E2FF;
      line-height: 0.88rem;
      text-align: center;
    }
  }

  .page-box::-webkit-scrollbar {
    display: none;
  }
}

</style>
