export default {
    get: function (key, type) {
        if (type) {
            return localStorage.getItem('cache-' + key)
        }
        return sessionStorage['cache-' + key]
    },
    getObj: function (key, type) {
        if (type) {
            if (localStorage.getItem('cache-' + key)) {
                try {
                    return JSON.parse(localStorage.getItem('cache-' + key))
                } catch (e) {
                    console.log(e)
                }
            }
        } else if (sessionStorage['cache-' + key]) {
            try {
                return JSON.parse(sessionStorage['cache-' + key])
            } catch (e) {
                console.log(e)
            }
        }
        return undefined
    },
    put: function (key, obj, type) {
        if (type) {
            if (typeof (obj) === 'string') {
                localStorage.setItem('cache-' + key, obj)
            } else {
                localStorage.setItem('cache-' + key, JSON.stringify(obj))
            }
        } else {
            if (typeof (obj) === 'string') {
                sessionStorage['cache-' + key] = obj
            } else {
                sessionStorage['cache-' + key] = JSON.stringify(obj)
            }
        }
    },
    remove: function (key, type) {
        if (type) {
            localStorage.removeItem('cache-' + key)
        } else {
            sessionStorage.removeItem('cache-' + key)
        }
    }
}
