<template>
  <div class="pump-query ly-wrap">
    <water-nav selected="4"></water-nav>
    <div class="ly-center">
      <div class="back-title">
        <div class="back" @click="goWayTab('/water/history','history')"></div>
        <div class="name">历史数据表</div>
        <!--        <div class="txt" @click="goWay('/water/chart')">切换到趋势图</div>-->
      </div>
    </div>
    <div class="ly-center">
      <div class="ly-lang-table">
        <div class="lang-info">
          <div class="time-box">
            <div class="sort-info">
              <div class="sort title-item" style="border-left: none;border-bottom: none;">序号</div>
              <div class="sort" v-for="(item,index) in vData" :key="index">{{ index + 1 }}</div>
            </div>
            <div class="time-info">
              <div class="item title-item" style="border-bottom: none;">时间</div>
              <div class="item" v-for="(item,index) in vData" :key="index">
                <p> {{ item.time.substring(0, 11) }}</p>
                <p> {{ item.time.substring(11, 19) }}</p>
              </div>
            </div>
          </div>
          <div class="slide-box">
            <div class="title-item" v-for="(item,index) in vName" :key="index">{{ item }}
              <div class="item" v-for="(item,i) in vData" :key="i">{{ item.data[index] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ly-paging :pagination="pageData" @sizeChange="sizeChange" @pageChange="pageChange"></ly-paging>

  </div>
</template>

<script>
import WaterNav from './modules/WaterNav'
import LyPaging from '@/components/LyPaging'
import {getAction} from '../../api/manage'
import storage from '../../store/storage.js'

export default {
  name: 'PumpQuery',
  components: {WaterNav, LyPaging},
  computed: {},
  data() {
    return {
      pageData: {},
      query: {
        pageNo: 1,
        pageSize: 10,
      },
      vName: [],
      vData: [],
      storageQuery: {},// 缓存数据
    }
  },
  mounted() {
  },
  created() {
    this.getParams()
  },
  methods: {
    getParams() {
      this.storageQuery = storage.getObj('condition')
      this.loadTitle()
      this.loadData()
    },
    loadTitle() {
      const body = {
        variableId: this.storageQuery.variableId.join(',')
      }
      getAction('/water/variable/queryNameByVariableId', body).then(res => {
        this.vName = res.data.result
      })
    },
    loadData() {
      const parameter = {
        pageNo: this.query.pageNo,
        pageSize: this.query.pageSize,
        variableId: this.storageQuery.variableId.join(','),
        start: this.storageQuery.start,
        end: this.storageQuery.end
      }
      getAction('/water/historyData/queryNewPageByVo', parameter).then(res => {
        this.vData = res.data.result.records
        this.pageData = res.data.result
      })
    },
    sizeChange(value) {
      this.query.pageNo = 1
      this.query.pageSize = value
      this.loadData()
    },
    pageChange(value) {
      this.query.pageNo = value
      this.loadData()
    },
    goWay(path) {
      this.$router.push({
        path: path,
      })
    },
    goWayTab(path, tab) {
      this.$router.push({
        path: path,
        query: {tab: tab}
      })
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
.pump-query {
  width: 100%;
  min-height: 100%;
  background-color: #FFFFFF;
}

.ly-nav-bar {
  width: 100%;
  height: 0.88rem;
  background-color: #DEEFFF;
  display: flex;

  .nav-item {
    text-align: center;
    flex: 1;
    line-height: 0.88rem;
    font-weight: 400;
    color: #333333;
    height: 0.88rem;
    font-size: 0.36rem;
    background-color: #DEEFFF;
    border-bottom: 0.04rem solid #DEEFFF;
  }

  .on {
    color: #0088FF;
    border-bottom: 0.04rem solid #0088FF;
  }

  .line {
    width: 1px;
    height: 0.36rem;
    background-color: #0088FF;
    position: relative;
    top: 0.32rem;
  }
}


.back-title {
  width: 100%;
  height: 0.88rem;
  text-align: center;
  line-height: 0.88rem;
  color: #333333;
  font-weight: bold;
  font-size: 0.36rem;
  display: flex;

  .back {
    width: 0.2rem;
    height: 0.88rem;
    background: url("~@/assets/img/more-dk.png") no-repeat center;
    background-size: 0.25rem;
    transform: rotate(-180deg);
  }

  .name {
    flex: 1;
  }

  .txt {
    text-align: right;
    width: 2rem;
    height: 0.88rem;
    font-weight: 400;
    font-size: 0.28rem;
  }
}

.ly-lang-table {
  width: 100%;
  height: 10rem;
  overflow-y: scroll;

  .lang-info {
    display: flex;

    .time-box {
      width: 2.5rem;
      display: flex;
      background-color: #DEEFFFFF;

      .title-item {
        height: 0.88rem;
        line-height: 0.88rem;
        overflow: hidden;
        background: #69B9FFFF;
        font-size: 0.22rem;
        text-align: center;
        color: #FFFFFF;
        font-weight: bold;
        border-right: 1px solid #CFEAFFFF;
      }

      .sort-info {
        width: 1rem;
        text-indent: 0.2rem;

        .sort {
          height: 0.88rem;
          overflow: hidden;
          line-height: 0.88rem;
          border-right: 1px solid #A6E1FFFF;
          //border-left: 1px solid #A6E1FFFF;
          border-bottom: 1px solid #A6E1FFFF;
        }
      }

      .time-info {
        flex: 1;
        overflow: hidden;

        .item {
          height: 0.88rem;
          overflow: hidden;

          p {
            line-height: 0.3rem;
            padding-top: 0.1rem;
          }

          border-bottom: 1px solid #A6E1FFFF;
        }
      }
    }

    .slide-box {
      flex: 1;
      display: inline-block; /*行内块元素*/
      white-space: nowrap; /*文本不会换行，文本会在在同一行上继续*/
      overflow-y: auto; /*可滑动*/
      font-weight: bold;

      .title-item {
        line-height: 0.885rem;
        background: #69B9FFFF;
        font-size: 0.22rem;
        text-align: center;
        color: #FFFFFF;
        font-weight: bold;
        border-right: 1px solid #CFEAFFFF;
        width: 1.6rem;
        display: inline-block; /*行内块元素*/
        border-bottom: 1px solid #CFEAFFFF;

        .item {
          width: 1.6rem;
          height: 0.88rem;
          line-height: 0.88rem;
          overflow: hidden;
          font-size: 0.3rem;
          color: #0088FF;
          background-color: #EDF6FFFF;
          border-right: 1px solid #A6E1FFFF;
          border-bottom: 1px solid #A6E1FFFF;
        }
      }
    }

    .slide-box::-webkit-scrollbar {
      display: none;
    }
  }
}

</style>

